function fixedElement(element, container)
{
    var element = element,
    container = container ? container : document.getElementById('calculator'),
    delay = 8,
    timeout = null,
    scrollTimeout = null,
    threshold = 60;

    var elementPosition = element.getBoundingClientRect(),
    offset = elementPosition.y + elementPosition.height,
    containerPosition = container.getBoundingClientRect(),
    containerOutOfView = containerPosition.y + containerPosition.height - elementPosition.height,
    containerOffset;

    /* https://github.com/wnr/element-resize-detector */
    elementResizeDetectorMaker().listenTo(container, function(containerElement) {
        if (containerElement.offsetHeight == 0)
        {
            containerOutOfView = 0;
        }
        else
        {
            scrollTimeout = setTimeout(function() {
                containerOutOfView = containerPosition.y + containerElement.offsetHeight - elementPosition.height;
            }, 300);
        }
    });

    document.addEventListener('scroll', function() {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(function(){
            var containerRect = container.getBoundingClientRect();
            var containerOffset = containerRect.y;
//containerOutOfView = containerPosition.y + containerRect.height - elementPosition.height;
            if (containerOffset < 0)
            {
                element.classList.add('sticked');
            }
            else
            {
                //element.classList.remove('sticked');
            }

            if (containerOutOfView < $(window).scrollTop())
            {
                element.classList.remove('sticked');
                element.classList.add('bottom');
            }
            else
            {
                element.classList.remove('bottom');
            }

            if (containerOutOfView === 0)
            {
                element.classList.remove('sticked');
                element.classList.remove('bottom');
            }

            }, delay);
    });
}

(function(window, document) {
    if (document.getElementById('flagship-sticky'))
    {
        var container = null;
        if (typeof document.getElementById('flagship-sticky').getAttribute('data-container') != 'undefined' && document.getElementById('flagship-sticky').getAttribute('data-container'))
        {
            container = document.getElementById('flagship-sticky').getAttribute('data-container');
        }
        if (document.getElementById('flagship-sticky').getBoundingClientRect().height < document.getElementById(container).getBoundingClientRect().height)
        {
            fixedElement(document.getElementById('flagship-sticky'), document.getElementById(container));
        }
        else
        {
            document.getElementById('flagship-sticky').classList.remove('flagship-sticky');
        }
    }
})(window, document);
