(function(window, document) {
    console.log('Start up arc8 scripts!');

    // Lazy load
    // -----------------------------------------------------------
    /*var lazyLoader = new LazyLoad({
        elements_selector: ".lazy"
    });*/

    // Configurator
    // -----------------------------------------------------------

    // configurator
    var variantOptions = $('.variant-wrap').find('.button');
    var variantIdOptions = $('input.variantIdOptions');
    var lightswitch = $('.lightswitch');
    var calctoggler = $('.calc-toggler');
    var calculator = document.getElementById('calculator');
    var productOptions = $('.select-wrap > select');
    var frameIds = [];
    var frames = $('.kit-wrap.frame').find('.variant-wrap').each(function() {
        frameIds.push($(this).attr('data-id'));
    });
    var preOrderDiscount = $('#pre-order-discount');

    variantOptions.on('click', function(ev) {
        ev.preventDefault();

        if (!$(this).parents('.variant-wrap').hasClass('selected'))
        {
            $(this).parents('.variant-wrap').siblings().removeClass('selected');
            $(this).parents('.variant-wrap').addClass('selected');
            getSelectedIds();
            getSelectedIdsWOFrame();
        }

        $(this).parents('.kit-wrap').removeClass('choose');
    });

    productOptions.on('change', function(ev) {
        var variant = $(this).attr('data-variantId'),
            option = $(this).find(':selected').attr('id');

        changeProductOptions(variant, option)
    });


    lightswitch.on('click', function() {
        if (!$(this).hasClass('mandatory'))
        {
            toggleKit($(this));
            getSelectedIds();
            getSelectedIdsWOFrame();
        }
    });

    $('.frame').find('.variant-wrap .button').on('click', function() {
        if ($(this).hasClass('in-stock'))
        {
            preOrderDiscount.slideUp(100);
        }
        else
        {
            preOrderDiscount.slideDown(100);
        }

        $('.frame').find('.variant-wrap').removeClass('set');
        $(this).parents('.variant-wrap').addClass('set');

        // enable «add to cart»
        $('#submitAddToCartPOP').removeClass('disabled');
        $('#submitAddToCartPOVP').removeClass('disabled');
        $('#submitAddToCartWHP').removeClass('disabled');
        $('#submitAddToCart').removeClass('disabled');
    });

    function changeProductOptions(variant, option) {
        var addToCartForm = $('form.add-to-cart');

        addToCartForm.each(function(key) {
            var definedOptions = $(this).find('.productOptions');
            if (!definedOptions.length) { return; }
            var optionsArray = definedOptions.val().split(',');
            var newArray = [];

            for (var i = 0; i < optionsArray.length; i++) {
                if (optionsArray[i].split(':')[0] == variant)
                {
                    newArray.push(variant + ':' + option);
                }
                else
                {
                    newArray.push(optionsArray[i]);
                }
            }

            definedOptions.val(newArray.join(','));
        });
    }

    function setProductOptions(variantIds) {
        var selectedVariants = variantIds.split(',');
        var newArray = [];

        for (var i = selectedVariants.length - 1; i >= 0; i--) {
            var selectWrap = $('#' + selectedVariants[i]).find('.select-wrap');

            selectWrap.each(function() {
                var variant = $(this).find('select').attr('data-variantid'),
                    option = $(this).find(':selected').attr('id');

                newArray.push(variant + ':' + option);
            });
        }

        $('input.productOptions').each(function() {
            $(this).val(newArray.join(','));
        });
    };

    function getSelectedIds() {
        var selectedVariants = '';
        $('.enabled .variant-wrap.selected').each(function( index ) {
            selectedVariants = selectedVariants + ((index ? ',' : '') + $( this ).attr('data-id'));
        });

        variantIdOptions.val(selectedVariants);
        setProductOptions(selectedVariants);
        getNewPrice(selectedVariants);
    }

    function getSelectedIdsWOFrame() {
        var selectedVariants = '';
        $('.enabled .variant-wrap.selected').each(function( index ) {
            if (!$(this).parents('.kit-wrap').hasClass('frame'))
            {
                selectedVariants = selectedVariants + ((index ? ',' : '') + $( this ).attr('data-id'));
            }
        });

        getAvailableStock(frameIds.join(','), selectedVariants);
    }

    function getNewPrice(variants) {
        $.ajax({
            url: "/_pricing?vId=" + variants,
            cache: false
        })
        .done(function( data ) {
            var priceData = data;
            $.ajax({
                url: "/is-dealer",
                cache: false
            }).done(function(data) {
                if (data === 'yes')
                {
                    var price = JSON.parse(priceData);
                    setB2BPrices(price.b2b_preorder, price.b2b_preorderVoucher, price.b2b_warehouse, price.b2c_olp);
                }
                else
                {
                    setPrice(priceData);
                }
            });
        });
    }

    function getAvailableStock(frames, variants) {
        $.ajax({
            url: "/get-stock-per-size?fId=" + frames + "&vId=" + variants,
            cache: true
        })
        .done(function( data ) {
            setStock(data)
        });
    }

    function setStock(frameIds)
    {
        var frames = JSON.parse(frameIds);

        Object.keys(frames).forEach(function(key) {
            if (frames[key] > 0)
            {
                $('#' + key).find('.button').addClass('in-stock').removeClass('no-stock');
            }
            else
            {
                $('#' + key).find('.button').addClass('no-stock').removeClass('in-stock');
            }
        });

        if ($('.variant-wrap.selected .button').hasClass('in-stock'))
        {
            preOrderDiscount.slideUp(100);
        }
        else if ($('.variant-wrap.selected .button').hasClass('no-stock'))
        {
            preOrderDiscount.slideDown(100);
        }
    }

    function toggleKit(element) {
        element.toggleClass('disabled');
        element.parents('.kit-wrap').toggleClass('disabled').toggleClass('enabled');
    }

    function setPrice(price) {
        var lineItemBtn = $('#lineitem-price > .price');

        lineItemBtn.text(price);
    }

    function setB2BPrices(pop, povp, whp, olp) {
        /* Value Add = delivery through dealer + frameset shipping */
        var valueAdd = 330;
        var lineItemBtnPOP = $('#lineitem-price-pop > .price'),
            lineItemBtnPOVP = $('#lineitem-price-povp > .price'),
            lineItemBtnPOVPInput = $('#lineitem-price-povp-input'),
            valueOfVoucher = $('.valueOfVoucher'),
            lineItemBtnWHP = $('#lineitem-price-whp > .price'),
            lineItemBtnOLP = $('#lineitem-price-olp > .price');

        lineItemBtnPOP.text(pop);
        lineItemBtnPOVP.text(povp);
        lineItemBtnPOVPInput.val(whp);
        valueOfVoucher.val(whp);
        lineItemBtnWHP.text(whp);
        lineItemBtnOLP.text(olp + valueAdd);
    }

    // expand options
    var selectTrigger = $('.select-trigger');
    selectTrigger.on('click', function(ev) {
        ev.preventDefault();
        $(this).parents('.kit-wrap').toggleClass('choose');
    });

    // Buy -> scroll-to and open calulator
    if (document.getElementById('calculator'))
    {
        var buyBtn = $('.buy');

        $(document).ready(function() {
            var offset = getRect(document.getElementById('calculator'));
            var offsetTop = offset.top;

            buyBtn.on('click', function(ev) {
                setPreset($(this).attr('data-setup'));
                $('html, body').animate({
                  scrollTop: (offsetTop - 80) + "px"
                }, 300);

                $('#calculator').find('.inner').slideDown(240).removeClass('hidden');
                $('#calculator').find('.left, .right').slideDown(240);
                $('#calculator').find('.calc-toggler').fadeIn(100).removeClass('hidden, close').addClass('open');

                // Change Url
                if (!updateQueryStringParameter(location.search, 'config', 'open'))
                {
                    window.history.replaceState({}, '', '?config=open');
                }
                else
                {
                    window.history.replaceState({}, '', updateQueryStringParameter(location.search, 'config', 'open'));
                }

                ev.preventDefault();
            });

            // Show calculator Button
            calctoggler.on('click', function(ev) {
                ev.preventDefault();

                $('html, body').animate({
                  scrollTop: (offsetTop - 80) + "px"
                }, 300);

                showCalcToggler($(this));
            });
        });

        $(window).resize(function() {
            offset = getRect(document.getElementById('calculator'));
            offsetTop = offset.top;
        });
    }

    function updateQueryStringParameter(uri, key, value) {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        }
        else {
            return uri + separator + key + "=" + value;
        }
    }

    function showCalcToggler(element) {
        var calc = $('#calculator').find('.left, .right'),
            btn = typeof element !== 'undefine' ? element : calc.find('.calc-toggler'),
            inner = $('#calculator').find('.inner'),
            locationSearch = location.search;

        if (element.hasClass('close'))
        {
            inner.removeClass('hidden');
            calc.slideDown(240).removeClass('hidden');
            element.removeClass('close').delay(240).addClass('open');

            if (!updateQueryStringParameter(location.search, 'config', 'open'))
            {
                window.history.replaceState({}, '', '?config=open');
            }
            else
            {
                window.history.replaceState({}, '', updateQueryStringParameter(location.search, 'config', 'open'));
            }
        }
        else
        {
            calc.slideUp(240);
            setTimeout(function() {
                element.removeClass('open').addClass('close');

                window.history.replaceState({}, '', updateQueryStringParameter(location.search, 'config', 'close'));
            }, 360);
        }
    }

    function setPreset(preset) {
        var parents = $('.kit-wrap');
        var calculator = $('#calculator');

        switch (preset) {

        case 'frameset':
            parents.removeClass('enabled').addClass('disabled').find('.lightswitch').addClass('disabled');
            $('.kit-wrap.frame, .kit-wrap.framekit').removeClass('disabled').addClass('enabled').find('.lightswitch').removeClass('disabled');
            getSelectedIds();
            getSelectedIdsWOFrame();
            return;

        case 'scratch':
            parents.removeClass('enabled').addClass('disabled').find('.lightswitch').addClass('disabled');
            $('.kit-wrap.frame').removeClass('disabled').addClass('enabled').find('.lightswitch').removeClass('disabled');
            getSelectedIds();
            getSelectedIdsWOFrame();
            return;

        case 'complete':
            parents.removeClass('disabled').addClass('enabled').find('.lightswitch').removeClass('disabled');
            $('.variant-wrap.selected').removeClass('selected');
            $('.variant-wrap.default').addClass('selected');
            calculator.find('option').prop("selected", false);
            $('option.default').prop("selected", true);
            getSelectedIds();
            getSelectedIdsWOFrame();
            return;

        default:
            return false;
        }

    }


    // Helpers
    function getRect(element) {
        const rect = element.getBoundingClientRect();
        return {
            top: rect.top,
            right: rect.right,
            bottom: rect.bottom,
            left: rect.left,
            width: rect.width,
            height: rect.height,
            x: rect.x,
            y: rect.y
        };
    }

    // Login Sign up
    // -----------------------------------------------------------
    var signInTrigger = $('#sign-in-trigger'),
        signInTarget = $('#sign-in-target'),
        closeSignIn = $('#close-sign-in');

    signInTrigger.on('click', function(ev) {
        ev.preventDefault();
        openModal(signInTarget);
    });

    closeSignIn.on('click', function(ev) {
        ev.preventDefault();
        $('body,html').removeAttr("style");
        signInTarget.removeClass('open');
    });

    // Tabs
    var tabNavItems = $('ul.tab-nav').find('li'),
        tabItems = $('.tab');

    tabNavItems.on('click', function() {
        var target = $('#' + $(this).attr('data-target'));

        tabNavItems.not(this).removeClass('active');
        $(this).addClass('active');

        tabItems.not('#' + $(this).attr('data-target')).removeClass('open');
        target.addClass('open');
    });

    $(document).on('keydown', function(ev) {
        if (signInTarget.hasClass('open') && ev.originalEvent.key === 'Escape')
        {
            $('body,html').removeAttr("style");
            signInTarget.removeClass('open');
        }
    });

    // Subscribe
    var js_subscribe = $('#subscribe-trigger, .subscribe-trigger'),
        js_close_subscribe = $('#close-subscribe, .close-subscribe'),
        subscribe_container = $('#subscribe'),
        subscribeTarget = $('#subscribe-target'),
        submitMailChimpForm = $('#submitMailChimpForm');

    js_subscribe.on('click', function(ev) {
        ev.preventDefault();

        openModal(subscribeTarget);
        $('body').addClass('is-not-scrollable');
    });

    js_close_subscribe.on('click', function(ev) {
        ev.preventDefault();

        subscribeTarget.removeClass('open');
        $('body,html').removeAttr("style");
        $('body').removeClass('is-not-scrollable');
    });

    subscribe_container.on('click', function(event) {
        if (event.target.id === 'contact')
        {
            // remove class from body
            subscribeTarget.removeClass('open');
            $('body,html').removeAttr("style");
            $('body').removeClass('is-not-scrollable');
        }
    });

    $(document).on('keydown', function(ev) {
        if ($('body').hasClass('is-not-scrollable') && ev.originalEvent.key === 'Escape')
        {
            // remove class from body
            subscribeTarget.removeClass('open');
            $('body,html').removeAttr("style");
            $('body').removeClass('is-not-scrollable');
        }
    });

    function isValidEmailAddress(emailAddress) {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        return pattern.test(emailAddress);
    };

    var parent, firstname, lastname, email;
    submitMailChimpForm.on('click', function(ev) {
        parent = $(this).parents('.newsletter-form');
        firstname = parent.find('#firstname').val();
        lastname = parent.find('#lastname').val();
        email = parent.find('#email').val();

        if (firstname.length > 2 && lastname.length > 3 && (email.length > 5 && isValidEmailAddress(email)))
        {
            parent.submit();
        }
        else
        {
            ev.preventDefault();
        }
    });


    // Select Modal
    var moreinfos = $('.more-infos'),
        modal = $('#modal'),
        closeBtn = modal.find('#close');

    moreinfos.on('click', function(ev) {
        ev.preventDefault();
        openModal(modal);
        loadData(modal, $(this).attr('data-href'));

        // change browser history
        window.kqCurrentpage = window.location.href;
        window.history.pushState({}, $(this).text(), $(this).attr('data-href'));
    });

    closeBtn.on('click', function(ev) {
        ev.preventDefault();
        closeModal(modal);
    });

    $(document).on('keydown', function(ev) {
        if (modal.hasClass('open') && ev.originalEvent.key === 'Escape')
        {
            closeModal(modal);
        }
    });

    window.onpopstate = function(ev) {
        // alert("location: " + document.location + ", state: " + JSON.stringify(ev.state));
        if ($('#modal').hasClass('open'))
        {
            closeModal($('#modal'));
            ev.preventDefault();
        }
    };

    function loadData(modal, href) {
        $.ajax({
            url: href,
            success: function(data){
                modal.find('.fa-sync').fadeOut();
                modal.find('.inner').html(data);
            }
        });
    }

    function openModal(modal) {
        modal.addClass('open');
        $('body,html').css('overflow', 'hidden');

    }

    function closeModal(modal) {
        $('body,html').removeAttr("style");
        modal.removeClass('open');
        modal.find('.inner').empty();
        modal.find('.fa-sync').fadeIn();

        // reset browser history
        window.history.pushState({}, null, window.kqCurrentpage);
    }

})(window, document);

// $(document).ready(function() {
//     var iOS = !!navigator.platform && /iPhone|iPod/.test(navigator.platform);

//     if (!iOS)
//     {
//         var selectInstances = $('select.select-target');

//         $.map( selectInstances, function( selectInstance, i ) {
//             selectInstance = new Select({
//                 el: document.getElementById(selectInstance.id),
//                 className: 'select-theme-chosen'
//             });
//         });
//     }
// });
