/* CSS */
import "@js/parts/css";

/* JS */
import "@js/parts/lazyloading";

import "@js/libs/_script";
import "@js/libs/app";
import "@js/libs/sticky";
import "@js/libs/element-resize-detector.min";
import "@js/libs/zooming.min";
// import "@js/libs/cdn.min";
// import "@js/libs/graphql";
// import "@js/libs/as-nav-for";
// import "@js/libs/flickity.pkgd.min";
// import "@js/libs/jquery.min";

// import "@js/parts/jquery";
// import "@js/parts/slick";

// import "lazyload";

// window.lazyload = lazyload;

import Zooming from 'zooming';

import $ from 'jquery';
window.jQuery = window.$ = $;

import 'slick-carousel-latest';

import TypeIt from "typeit";
window.TypeIt = TypeIt;

import axios from "axios";
window.axios = axios;

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';

window.Alpine = Alpine;
Alpine.start();
Alpine.plugin(focus);

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}

// Dispatch the event.
const event = new Event("vite-dev-server-running");
document.dispatchEvent(event);
